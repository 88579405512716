.gallerynav-section {
    width: 100vw;
    background-color: whitesmoke;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 2rem;
    padding: 15px 50px;

    img {
        // max-width: 300px;
        max-height: 350px;
        margin: 50px 50px;
        // transition: all 0.2s ease-in-out;

        // &:hover {
        //     transform: scale(1.3);
        //     cursor: pointer;
        // }

        @media (max-width: 650px) {
            max-width: 260px;
            margin: 20px 20px;
        }
    }

    a {
        text-decoration: none;
    }

    h5 {
        text-decoration: none;
        color: black;
        text-align: center;
        position: relative;
        bottom: 20px;

        @media (max-width: 650px) {
            
        }
    }
}

.ciep-pomp {
    width: 262px;
}