.inner-gallery {
    background-color: whitesmoke;
}

.back {
    background-color: whitesmoke;
    color: black;
    text-align: center;
    padding-top: 20px;

    &:hover {
        cursor: pointer;
    }
}

.gallery-section {
    width: 100vw;
    background-color: whitesmoke;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 2rem;
    padding: 10 50px;


    img {
        max-width: 400px;
        max-height: 300px;
        margin: 30px 50px;
        transition: all 0.2s ease-in-out;

        @media(max-width: 650px) {
            max-width: 280px;
          }

        &:hover {
            transform: scale(1.5);
            cursor: pointer;
        }
    }
}