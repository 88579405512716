.home-wrapper {
    width: 100vw;

    .home-logo {
        width: 100%;
        height: calc(100vh - 80px);
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .img {
            position: relative;
            bottom: 60px;
            width: 450px;
            height: 450px;
            background-image: url('../../assets/log.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            // transform: scale(1.2);
        }

        img {
            position: relative;
            bottom: 60px;
            max-width: 450px;
            max-height: 450px;
            // transform: scale(1.2);

            @media (max-width: 650px) {
                width: 300px;
                height: 300px;
                bottom: 20px;
            }
        }

        h1 {
            font-size: 3rem;
            color: lightblue;

            @media (max-width: 650px) {
                font-size: 2.2rem;
                text-align: center;
            }
        }
    }
}
