.partners-section {
    width: 100vw;
    background-color: white;
    padding-top: 1px;

    h2 {
        color: darkgray;
        margin-bottom: 30px;
        text-align: center;

        @media (max-width: 650px) {
            font-size: 36px;
        }
    }

    .logos-container {
        width: 100%;
        padding: 0 40px;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width: 650px) {
            padding: 0 5px;
            padding-bottom: 30px;
        }

        img {
            margin: 1px 5px;
            width: 240px;
            height: 70px;
            object-fit: fill;
            transform: scale(0.7);

            @media (max-width: 650px) {
                margin: 1px 2ppx;
                width: 130px;
                height: 35px;
                transform: scale(0.7);
            }

        }

        .logo5 {
            transform: scaleX(0.6);
        }
    }
}