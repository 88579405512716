footer {
    width: 100vw;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;

    p {
        color: lightblue;
        font-weight: 300;
    }

    @media (max-width: 650px) {
        padding: 0 20px
        }
}