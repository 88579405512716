.about-section {
    width: 100vw;
    height: calc(100vh - 80px);
    background-color: white;
    color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 650px) {
        flex-wrap: wrap;
        height: auto;
    }

    .about-img-holder {
        width: 500px;
        height: 500px;
        position: relative;
        top: 70px;

        @media (max-width: 650px) {
            width: 100vw;
            height: 220px;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            max-width: 100%;
            object-fit: fill;
        }
    }

    .about-text-holder {
        width: 500px;
        height: 550px;

        @media (max-width: 650px) {
            height: auto;
            padding: 0 20px;
        }

        .about-title {
            font-size: 1.7rem;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .about-text {
            font-size: 1.1rem;
            margin-bottom: 10px;
        }
    }
}