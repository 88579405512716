.contact-section {
    width: 100vw;
    height: 500px;
    background-color: whitesmoke;
    padding-top: 200px;

    @media (max-width: 650px) {
        padding-top: 20px;
        padding-bottom: 60px;
    }

    .buttons-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media (max-width: 650px) {
            height: 100%;
            flex-direction: column;

        }

        h4 {
            color: black;
            font-size: 1.4rem;
            text-align: center;
            margin-bottom: 20px;
        }

        a {
            width: 240px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            background-color: black; 
            color: white;

            &:hover {
                color: lightblue;
            }
        }
    }
}