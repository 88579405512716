.button__container {
    background: lightblue;
    padding: 7px;
    margin-right: 50px;
  
    @media(max-width: 650px) {
      padding: 5px;
      margin-right: 30px;
    }
  
    .toggle__button {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background:  lightblue;
      height: 24px;
      width: 30px;
      border: none;
      cursor: pointer;
      padding: 0px;
      box-sizing: border-box;
  
      // @media(max-width: 650px) {
      //   height: 20px;
      //   width: 25px;
      // }
    
    
      .toggle__button:focus {
        outline: none;
      }
      
      .toggle__button__line {
        width: 30px;
        height: 3px;
        background: black;
  
        // @media(max-width: 650px) {
        //   width: 25px;
        // }
      }
    }
  
    @media (min-width: 1002px) {
      display: none;
    }
  }
  