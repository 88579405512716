.form-section {
    width: 100vw;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #efefef;
    color: black;
}

.form {
    width: 600px;

    @media (max-width: 650px) {
        width: 300px;
    }
}

.input-field [type=text] {
    color: black;
}

.btn {
    background-color: lightblue;
    margin-top: 20px;
}
