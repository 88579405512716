.about-section {
    width: 100vw;
    height: calc(100vh - 80px);
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 650px) {
        flex-wrap: wrap;
        height: auto;
    }

    .about-img-holder {
        width: 500px;
        height: 500px;
        

        @media (max-width: 1056px) {
            width: 400px;
        }

        @media (max-width: 650px) {
            width: 100vw;
            height: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            max-width: 100%;
            object-fit: fill;
            position: relative;
            bottom: 50px;
            left: 50px;

            @media (max-width: 1056px) {
                bottom: 0;
                left: 0;
            }

            @media (max-width: 650px) {
                top: 40px;
                transform: scale(0.8);
            }
        }
    }

    .about-text-holder {
        width: 500px;
        height: 550px;
        color: grey;

        @media (max-width: 1056px) {
            width: 400px;
        }

        @media (max-width: 650px) {
            height: auto;
            padding-top: 100px;
        }

        .about-title {
            font-size: 1.7rem;
            color: lightblue;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .about-text {
            font-size: 1.1rem;
            margin-bottom: 10px;
            color: lightblue;
            padding-right: 0 10px;
            text-align: justify;
            line-height: 19px;

            @media (max-width: 1056px) {
                font-size: 1.5;
            }
        }

        .bold {
            font-weight: 700;
            letter-spacing: 1px;
            font-size: 1.2rem;
            text-align: center;
            // text-decoration: underline;
        }

        .special {
            margin-top: 14px;
            margin-bottom: 14px;
        }
    }
}



.right-arrow {
    width: 20px;
    height: 17px;
    filter: invert(85%) sepia(28%) saturate(264%) hue-rotate(152deg) brightness(92%) contrast(95%);
    margin-right: 7px;
    position: relative;
    top: 3px;
}

.special-text-box {
    border: 1px solid lightblue;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: white;

    ol {
        list-style-type: square;

        li {
            font-size: 1.1rem;
            margin-bottom: 10px;
            color: lightblue;
            line-height: 19px;
            position: relative;
            left: 10px;
        }
    }
}