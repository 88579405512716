.navbar {
    width: 100vw;
    height: 80px;
    background-color: #000000 !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 10;

    img {
        height: 80px;
        margin-left: 80px;

        @media (max-width: 1002px) {
            height: 60px;
            margin-left: 40px;
        }

        @media (max-width: 650px) {
            height: 60px;
            margin-left: 20px;
        }
    }

    ul {
        width: 800px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        margin-right: 40px;

        @media (max-width: 1002px) {
        display: none;
        }
    }

    li {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    a {
        text-decoration: none;
        color: white;
        font-size: 1.4rem;

        &:hover {
            cursor: pointer;
            color: lightblue;
        }
    }
}

