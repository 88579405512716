.side__menu {
    //height: 100vh;
    background: black;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    width: 100%;
    // width: 70%;
    // max-width: 300px;
    z-index: 9999;
    transform: translateY(-102%);
    transition: transform 0.3s ease-out;

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;

      li {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        //border-top: 1px solid black;

        // &:hover {
        //   background-color: darken(white, 10%);
        // }

        .menu__link__element {
          text-decoration: none;
          color: white;
          font-size: 1.2rem;
          text-transform: uppercase;
        }
      }
    }
  }
  
.side__menu.open {
  transform: translateX(0);
}