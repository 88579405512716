.info-container {
    width: 100vw;
    height: 380px;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    background-color: #212020;

    @media (max-width: 650px) {
        flex-wrap: wrap;
        height: auto;
    }

    .info-section-box {
        width: 380px;
        display: flex;
        flex-direction: column;
        color: lightgray;
        justify-content: space-between;

        @media (max-width: 650px) {
            width: 100%;
            margin-bottom: 20px;
            align-items: center;
        }

        .info-title {
            font-size: 1.4rem;
            color: lightblue;
            margin-bottom: 15px;
        }

        .info-name {
            font-size: 1.3rem;
            margin-bottom: 15px;
        }

        span {
            margin-bottom: 5px
        }

        .margbot {
            margin-bottom: 15px;
        }

        a {
            text-decoration: none;
            color: white;
            margin-bottom: 10px;
            margin-top: 2px;
            font-size: 1.1rem;

            &:hover {
                color: lightblue;
                cursor:  pointer;
            }
        }
    }

    .altalign {
        align-items: center;
    }

    button {
        width: 100px;
        height: 40px;
        font-size: 18px;
        background-color: snow;
        color: black;
        position: relative;
        bottom: 20px;
        cursor: pointer;
    }
}

.social-section-box {
    @media (max-width: 650px) {
        height: 160px;
    }

    .social-icons-holder {
        width: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        .facebook {
            width: 40px;
            height: 40px;
            background-image: url('../../assets/facik.png');
            background-position: center;
            background-size: cover;
            cursor: pointer;
        }
    }
}