.videos-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px 0;

    .player-wrapper {
        width: 500px;
        height: 280px;
        margin: 30px 40px;

        @media (max-width: 650px) {
            width: 80%;
            height: auto;
        }
    }
}