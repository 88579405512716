@import './components/navbar.scss';
@import './components/home.scss';
@import './components/info.scss';
@import './components/footer.scss';
@import './components/engage.scss';
@import './components/about.scss';
@import './components/gallerynav.scss';
@import './components/gallery.scss';
@import './components/offer.scss';
@import './components/contact.scss';
@import './components/form.scss';
@import './components/partners.scss';
@import './components/news.scss';
@import './components/subsidies.scss';
@import './components/hamburger.scss';
@import './components/sidemenu.scss';
@import './components/cookies.scss';
@import './components/send.scss';
@import './components/homeabout.scss';
@import './components/rodo.scss';
@import './components/videos.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: black;
    color: white;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
}
