.subsidies-section {
    width: 100vw;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;


    .subsidies-box {
        width: 60%;
        margin-top: 40px;
        margin-bottom: 40px;

        @media (max-width: 650px) {
            width: 80%;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .subsidies-text {
            font-size: 1.5rem;
            margin: 10px 0;

            @media (max-width: 650px) {
                font-size: 1.3rem;
            }
        }

        button {
            font-size: 1.5rem;
            padding: 5px 30px;
            background-color: black;
            color: lightblue;
            margin-bottom: 20px;

            @media (max-width: 650px) {
                font-size: 1.3rem;
            }

            &:hover {
                cursor: pointer;
                color: white;
            }
        }
    }
}