.engage-container {
    width: 100vw;
    height: 280px;
    padding-top: 30px;
    background-color: lightblue;

    @media (max-width: 650px) {
        height: auto;
        padding-bottom: 30px;
    }

    h2 {
        color: black;
        font-size: 2rem;
        text-align: center;
        margin-bottom: 30px;
    }

    .buttons-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;

        h4 {
            color: black;
            font-size: 1.4rem;
            text-align: center;
            margin-bottom: 20px;
        }

        a {
            width: 240px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            background-color: black; 
            color: white;

            @media (max-width: 650px) {
                margin-bottom: 20px;
            }

            &:hover {
                color: lightblue;
            }
        }
    }
}